import React from "react";
import { TabView } from "primereact/tabview";
import { TabPanel } from "primereact/tabview";
import { MonthWiseInstallations } from "./MonthWiseInstallations";
import { RealTimeData } from "./RealTimeData";
import { RealTimeDayWise } from "./RealTimeDayWise";

export const RealTimePerformance = () => {

    return (

        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <TabView>
                        <TabPanel header="Day Wise Performance">
                            <RealTimeDayWise />
                        </TabPanel>
                        <TabPanel header="Month Wise Performance">
                            <RealTimeData />
                        </TabPanel>
                    </TabView>
                </div>
            </div>
        </div>
    )
}