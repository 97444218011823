    import React from "react";
    import SalesStatComp from "./SalesStatComp";

    export const PerformanceOverallStat = (props) => {

        // console.log(props.inventoryData);

        return (
            <>
                <div className='card'>                
                <h6>CVES Performance Report</h6>

                {
                    (props.accType.code == 'account' && props.filterAccountsValue?.name == 'All' && props.machineType.code == 'ctvm') &&

                    <div className="p-grid">
                        {/* <SalesStatComp header='Total No of Accounts' icon='pi pi-user'  value={props.distributorsValue ? (props.distributorsValue - 1) : '-'} loading={props.loading} /> */}
                        <SalesStatComp header='0 to 1200 Cups' icon='pi pi-user' value={<><div><span>CVES Count : 1000</span> <span>Cups : 20</span></div></>} loading={props.loading} />
                        <SalesStatComp header='1201 to 2000 Cups' icon='pi pi-star-o' value={'-'} loading={props.loading} />
                        <SalesStatComp header='2001 to 3000 Cups' icon='pi pi-slack' value={'-'} loading={props.loading} />
                        <SalesStatComp header='Above 3000 Cups' icon='pi pi-wallet' value={'-'} loading={props.loading} />
                    </div>
                }
            
                </div>
            </>
        )   
            
    }