export const responsiveOptions = [
    {
        breakpoint: '1024px',
        numVisible: 5
    },
    {
        breakpoint: '960px',
        numVisible: 4
    },
    {
        breakpoint: '768px',
        numVisible: 3
    },
    {
        breakpoint: '560px',
        numVisible: 1
    }
];

export const customerTypeOptions = [
    { name: 'Account'},
    { name: 'Lead' }
];

export const billingPatternOptions = [
    { name: 'Pre Consumption', code:'preconsumption' },
    { name: 'Post Consumption', code:'postconsumption' }
];

export const invoiceCycleOptions = [
    { name: 'Every Month End', code:'month_end' },
    { name: 'Monthly (30 Days)', code:'monthly' },
    { name: 'Weekly (7 Days)', code:'weekly' }
];

export const maintenancePackages = [
    { name: 'Monthly',code:'monthly'},
    { name: 'Quarterly',code:'quarterly'},
    { name: 'HalfYearly',code:'halfyearly'},
    { name: 'Yearly',code:'yearly'}
];

export const maintainenceterms = [
    { name: 'Postpaid', code:'postpaid'},
    { name: 'Prepaid', code:'prepaid'}
];

export const typeOfDeposite = [
    { name: 'Refundable', code:'refundable'},
    { name: 'Non Refundable', code:'nonrefundable'}
];


export const dropdownItems = [
    { name: 'Demo', code: 'Demo' },
    { name: 'Commercial', code: 'Commercial' }
];

export const demoPurposeDropdownItems = [
    { name: 'General Lead', code : 'generalLead' },
    { name: 'Invester Pitch', code : 'investerPitch' },
    { name: 'Special event', code : 'specialEvent' }
];

export const despensingOptionDropdownList = [
    { name: 'Free', code:'free' },
    { name: 'Paid', code:'paid'}
];

export const cupRechargeDropdownList = [
    { name: 'Postpaid', code:'postpaid' },
    { name: 'Prepaid', code:'prepaid'}
];

export const paymentDefault = [
    { name: 'Default', code:'default' }
];

export const multiselectValues = [
    { name: 'All' },
    { name: 'Demo' },
    { name: 'Commercial' }
];

export const packageDropdownList = [
    { name:"Buy", code:"buy"},
    { name:"Rent", code:"maintenance"},
    { name:"Deposit", code:"deposit"},
    { name:"Rent and Deposit", code:"maintenance/deposit"},
    { name:"Free", code:"none"}
]    



   
export const pantryInfoSchema = {
    blocked: true,
    cno: "",
    cnoAlert: true,
    email: "",
    endDate: "",
    endInter: "",
    keypad: 3212,
    name: "",
    startDate: "",
    startInter: ""
}

export const pantryAuthSchema = {
    default: false,
    cup_wise: false,
    quantity: false
}

export const pantryAutorizationTem = {
    cleaning: false,
    cup: false,
    price: false,
    refilling: false
}

export const onboardMainTemp = {
    customer: null,
    d_id: null,
    lead: false,
    machine: "",
    siteName: "" 
}

export const supermans = {
    accountmanager: "",
    marketmanager: "",
}


export const paidBeverageSetting = {
    rfid: true,
    nfc: false,
    fingerprint: false,
    qr: false,
    onlinePayment: false,
    password : false,
    all: false
}

export const corefacilityTemp = {
    admin_default: false,
    admins: [],
    billing_default: false,
    facname: ""
} 

export const facilityTemplate = {
    d_id: ["self"],
    userid: null,
    facility:[{
        // admin_default: false,
        // admins: [],
        // billing_default: false,
        // facname: "",
        
        // address: {
        //     address: "",                
        //     city: {id: "", name: "", state_id: ""},
        //     country: {id: "", sortname: "", name: "", phonecode: ""},
        //     state: {id: "", name: "", country_id: ""}
        // },

        // billing_details: {

        //     addr: "",
        //     city: {id: "", name: "", state_id: ""},
        //     cmpname: "",
        //     country: {id: "", sortname: "", name: "", phonecode: ""},
        //     gstnumber: "",
        //     locality: "",
        //     state: {id: "", name: "", country_id: ""}
        // }
    }
    ]
}

export const facAddresstemp = {

        address: "",                
        city: {id: "", name: "", state_id: ""},
        country: {id: "", sortname: "", name: "", phonecode: ""},
        state: {id: "", name: "", country_id: ""}
}

export const facBillingDetails = {
        addr: "",
        city: {id: "", name: "", state_id: ""},
        cmpname: "",
        country: {id: "", sortname: "", name: "", phonecode: ""},
        gstnumber: "",
        locality: "",
        state: {id: "", name: "", country_id: ""}
}

export const kettleCommandConfirmation = {
    kettle_stirrer : 'Are you sure you want to ON/OFF the stirrer?',
    kettle_tea_motor : 'Are you sure you want to ON/OFF the motor?',
    kettle_valve : 'Are you sure you want to ON/OFF the valve?',
    kettle_machine_mode : 'Are you sure you want to change the kettle machine mode?',
    kettle_heater : 'Are you sure you want to ON/OFF the heater?',
    // kettle_set_temp : 'Are you sure you want to set kettle temperature?'
}

export const kettleConfigurationDefaults = {
    id: '',
    kettle_disp_time: 1.6,
    kettle_htr_off1	:	5,
    kettle_htr_off2	:	20,
    kettle_htr_off3	:	20,
    kettle_htr_off4	:	40,
    kettle_htr_mode	:	1,
    kettle_valve_calfact :	0.04555,
    kettle_set_cup_count :	50,
    kettle_disp_selection_flg :	1,
    kettle_door_unlock_attempts	:	2,
    kettle_cup_count_buffer	:	5,
    kettle_stirrer_on_time	:	5,
    kettle_stirrer_off_time	:	15,
    kettle_stirrer_refill_on_time:	30,
    kettle_online_refill_mode :	0,
    kettle_stage_mode : 1,
    kettle_refill_tea_water	: 0 
}


export const headerImage = [
        {
            "id":"1",
            "image":"7.png"
        },
        {
            "id":"2",
            "image":"6.png"
        },
        {
            "id":"3",
            "image":"8.png"
        },
        {
            "id":"4",
            "image":"9.png"
        },
        
        {
            "id":"5",
            "image":"1.png"
        },
        {
            "id":"6",
            "image":"2.png"
        },
        {
            "id":"7",
            "image":"3.png"
        },
        {
            "id":"8",
            "image":"4.png"
        },
        {
            "id":"9",
            "image":"5.png"
        }
]


export const unlockCategory = [
    { name : 'Operational / Manual issue', code: 'operational' },
    { name : 'Technical Issue', code: 'technical' }
]

export const manualUnlockReasons = [
    { reason : "Barcode used but canister not removed by mistake" },
    { reason : "Canister cleaning" },
    { reason : "Canister placed incorrectly after refilling" },
    { reason : "Canister Maintenance needed" }
]

export const technicalUnlockReasons = [
    { reason : "Canister lock is stuck" },
    { reason : "Garbage in the canister unlocking command" },
    { reason : "Prefix has hardened" }
]

export const unlockReasons = [
    { reason : "Barcode used but canister not removed by mistake" },
    { reason : "Canister lock is stuck" },
    { reason : "Canister cleaning" },
    { reason : "Garbage in the canister unlocking command" },
    { reason : "Canister placed incorrectly after refilling" },
    { reason : "Prefix has hardened" },
    { reason : "Canister Maintenance needed" },
]

export const networkTypeOptions = [
    { name : 'Airtel'},
    { name : 'Airtel-IOT'},
    { name : 'VI-IOT'},
    { name : 'Jio'}
]

export const statusOptions = [
    { name : 'All', code : 'all'},
    { name : 'Active', code : 'Active'},
    { name : 'Inactive', code : 'Inactive'},
    // { name : 'Demo', code : 'Demo'},
]

export const merchantTypeOptions = [
    { name : 'Paytm', code : 'PAYTM'},
    { name : 'Phonepe', code : 'PHONEPE'}
]
