import React, { useState, useEffect, useRef, useContext } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { InputSwitch } from 'primereact/inputswitch';
// RazorPay
import useRazorpay from "react-razorpay";
import { permissionContext } from '../../AppWrapper';

import ApiServices from '../../service/api/ApiServices';
import e from 'cors';
import { failureToast, successToast } from '../Common/CommonFunctions';
import { CommonBodyForFormattedStrTable, CommonBodyForTable } from '../Common/CommonComponents';
const apiServices = new ApiServices()

const saved = localStorage.getItem("user");
const initialValue = JSON.parse(saved);

export const Employees = (props) => {

    const rolePermissions = useContext(permissionContext)
    const Razorpay = useRazorpay();

    const isLive = true;
    const RPAY_KEY = isLive ? "rzp_live_33WIg85yHAfbEv" : "rzp_test_J1drhhEipPb1Rl"
    const RPAY_SECRET = isLive ? "H4QUfaVxXmL2mHKj1jCVpuZX" : "c7FvosSLkmvjE7anmzS8fNiu"

    const [loading, setLoading] = useState(true)
    const [dataLoading, setDataLoading] = useState(true)
    const [allMachines, setAllMachines] = useState(null)
    const [nfcMachines, setNfcMachines] = useState(null)
    const [rfidMachines, setRfidMachines] = useState(null)
    const [customerRfidMachines, setCustomerRfidMachines] = useState(null)

    const [deleteEmpDialog, setDeleteEmpDialog] = useState(false);
    const [addNfcDialog, setAddNfcDialog] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [selectedEmp, setSelectedEmp] = useState(null);
    const [selectedCard, setSelectedCard] = useState(null);
    const [selectedPeriod, setSelectedPeriod] = useState(null)
    const [multiMachines, setMultiMachines] = useState(null);
    const [singleMachine, setSingleMachine] = useState(null);
    const [selectedLimit, setSelectedLimit] = useState(null);
    const [selectedAssigned, setSelectedAssigned] = useState(null);
    const [employeeTemplate, setEmployeeTemplate] = useState({});
    // const [employeeTemplate, setEmployeeTemplate] = useState(defaultData);

    const [editedEmployee, setEditedEmployee] = useState(null)
    const [selectedDates, setSelectedDates] = useState(null)
    const [selectedMach, setSelectedMach] = useState(null)
    const [machineEmp, setMachineEmp] = useState(null)
    const [rechargedEmp, setRechargedEmp] = useState([])
    const [minDate, setMinDate] = useState(null)
    const [toDate, setToDate] = useState(null)
    const [nextYrDate, setNextYrDate] = useState(null)
    const [output, setOutput] = useState(null)
    const [onlineValidate, setOnlineValidate] = useState(false)
    const [dataToExport, setDataToExport] = useState([])

    const [rechargeDialog, setRechargeDialog] = useState(false)
    const [rechargeAmt, setRechargeAmt] = useState(0)
    const [rechargeCups, setRechargeCups] = useState(0)

    const [bulkRechargeDialog, setBulkRechargeDialog] = useState(false)
    const [selectedCards, setSelectedCards] = useState([])
    const [ordersId, setOrdersId] = useState(null)
    const [transDialog, setTransDialog] = useState(false)
    const [allTransations, setAllTransations] = useState(null)
    const [defaultPayloadDates, setDefaultPayloadDates] = useState({})
    const [selectedRfidForEdit, setSelectedRfidForEdit] = useState(null)


    const toast = useRef(null);
    const dt = useRef(null);


    const cardTypeDropdownItems = [
        { name: 'RFID', code: 'rfid' },
        { name: 'NFC', code: 'nfc' }
    ];

    const assignedByoptions = [
        { name: 'Cups', code: 'cups' },
        { name: 'Cost', code: 'cost' }
    ]

    const periodDropdownItems = [
        { name: 'Always', code: 'always' },
        { name: 'Timely', code: 'date' },
        { name: 'Recharge', code: 'recharge' }
    ];

    const limitDropdownOptions = [
        { name: 'Daily', code: 'daily' },
        { name: 'Weekly', code: 'weekly' },
        { name: 'Monthly', code: 'monthly' },
        { name: 'None', code: 'none' }
    ]

    useEffect(() => {
        getData(props.mchEmployee)
    }, [props.mchEmployee])

    useEffect(() => {
        setLoading(props.loading)
    }, [props.loading])


    useEffect(() => {
        setDeleteEmpDialog(props.deleteDialog)
        setAddNfcDialog(props.addNfcDialog)
        getStartDate()
    }, []);


    useEffect(() => {
        getMachines(props.allMachines)
    }, [props.allMachines])


    useEffect(() => {
        setSelectedMach(props.selectedMachine)
    }, [props.selectedMachine])


    const getData = (data) => {
        console.log(data);
        if (data?.length > 0) {
            const arr = []
            data?.map((x) => { arr.push({ ...x, cupCounts: x.cup_count?.halfcup }) })
            console.log(arr);
            setMachineEmp(arr)

            const emplToRecharge = arr.filter((x) => {
                if (x?.duration?.period == 'recharge') {
                    return x;
                }
            })
            console.log(emplToRecharge);
            setRechargedEmp(emplToRecharge)

            const forExp = [];

            arr.map((x, i) => {
                const data = { Sr: i + 1, Rfid: x.rfid, Name: x.name, CupCounts: x.cupCounts }
                forExp.push(data)
            })

            console.log(forExp);
            setDataToExport(forExp)
        }

        setDataLoading(false)
    }

    const getMachines = (data) => {
        if (data) {
            setAllMachines(data)
            const nfcMachines = data.filter((x) => { if (x?.mchmode?.paymode?.nfc) { return x; } })
            const rfidMachines = data.filter((x) => { if (x?.mchmode?.paymode?.rfid) { return x; } })
            setNfcMachines(nfcMachines)
            setRfidMachines(rfidMachines)

        }
    }


    const getStartDate = () => {
        let date = new Date();
        const yr = date.getFullYear();
        const NextYr = date.getFullYear() + 1;
        const mnth = ("0" + (date.getMonth() + 1)).slice(-2);
        const day = ("0" + date.getDate()).slice(-2);
        const todayDate = `${yr}-${mnth}-${day}`;
        const NextYrDate = `${NextYr}-${mnth}-${day}`;
        console.log(todayDate);
        setToDate(todayDate)
        setNextYrDate(NextYrDate)

        const payloadData = {
            startDate: { year: new Date(todayDate).getFullYear(), month: parseInt(("0" + (date.getMonth() + 1)).slice(-2)), day: parseInt(("0" + date.getDate()).slice(-2)) },
            endDate: { year: new Date(NextYrDate).getFullYear(), month: parseInt(("0" + (date.getMonth() + 1)).slice(-2)), day: parseInt(("0" + date.getDate()).slice(-2)) },
            startInter: "00:00",
            endInter: "23:59"
        }

        setDefaultPayloadDates(payloadData)

    }

    const changeInputs = (e) => {

        if (e.target.name == 'name' || e.target.name == 'rfid') {
            var data = { ...employeeTemplate, [e.target.name]: e.target.value }
        } else if (e.target.name == 'cardtype') {
            setSelectedCard(e.value)
            var data = { ...employeeTemplate, [e.target.name]: e.value.code }
        } else if (e.target.name == 'machineid') {
            console.log(e.value);
            setSingleMachine(e.value)
            setMultiMachines(null)
            // setMultiMachines(e.value)

            // const machineArr = e.value?.map((x) => { return x?.id })
            // var data = { ...employeeTemplate, [e.target.name]: machineArr }

            var data = { ...employeeTemplate, [e.target.name]: [e.value.id] }

        } else if (e.target.name == 'machineidNfc') {
            setSingleMachine(null)
            console.log(e.value)
            setMultiMachines(e.value)
            const multimach = e.value?.map((x) => { return x?.id })
            var data = { ...employeeTemplate, machineid: multimach }
        } else if (e.target.name == 'period' || e.target.name == 'assignby' || e.target.name == 'cup_limit') {

            if (e.target.name == 'period') {

                setSelectedPeriod(e.value)
                if (e.value.code == 'recharge') {
                    setSelectedAssigned({ name: 'Cups', code: 'cups' })
                    // var data = { ...employeeTemplate, duration: { ...employeeTemplate.duration, [e.target.name]: e.value.code, assignby: 'cups', startDate: new Date().toISOString(), onlineValidate: true } }
                    var data = {
                        ...employeeTemplate,
                        duration: {
                            ...employeeTemplate.duration,
                            assignby: 'cups',
                            startDate: new Date().toISOString(),
                            onlineValidate: true,
                            [e.target.name]: e.value.code
                        }
                    }

                } else {
                    setSelectedAssigned(null)
                    // var data = { ...employeeTemplate, duration: { ...employeeTemplate.duration,  [e.target.name]: e.value.code } }
                    var data = {
                        ...employeeTemplate,
                        duration: {
                            ...employeeTemplate.duration,
                            ...defaultPayloadDates,
                            [e.target.name]: e.value.code
                        }
                    }
                }

            } else if (e.target.name == 'assignby') {
                setSelectedAssigned(e.value)
                var data = { ...employeeTemplate, duration: { ...employeeTemplate.duration, [e.target.name]: e.value.code } }

            } else if (e.target.name == 'cup_limit') {
                setSelectedLimit(e.value)
                var data = { ...employeeTemplate, duration: { ...employeeTemplate.duration, [e.target.name]: e.value.code } }
            }


        } else if (e.target.name == 'cup_count') {
            if (e.target.value > 255) {
                setOutput("")
            } else {
                setOutput(e.target.value)
                var data = { ...employeeTemplate, [e.target.name]: { fullcup: Number(e.target.value), halfcup: Number(e.target.value) } }
            }
        } else if (e.target.name == 'costpercup' || e.target.name == 'totalcost') {
            var data = { ...employeeTemplate, duration: { ...employeeTemplate.duration, [e.target.name]: e.target.value } }
        } else if (e.target.name == 'startDate' || e.target.name == 'endDate') {
            console.log(e.target.value);
            setMinDate(e.target.value)

            let date = new Date(e.target.value),
                mnth = ("0" + (date.getMonth() + 1)).slice(-2),
                day = ("0" + date.getDate()).slice(-2);

            // var data = { ...employeeTemplate, duration: { ...employeeTemplate.duration, [e.target.name]: { year: date.getFullYear(), month: parseInt(mnth), day: parseInt(day) } } }
            var data = { ...employeeTemplate, duration: { ...employeeTemplate.duration, [e.target.name]: new Date(e.target.value).toISOString() } }
        } else if (e.target.name == 'startInter' || e.target.name == 'endInter') {
            console.log(e.target.value);
            var data = { ...employeeTemplate, duration: { ...employeeTemplate.duration, [e.target.name]: e.target.value } }
        } else if (e.target.name == 'onlineValidate') {
            console.log(e.value);
            setOnlineValidate(e.value)

            if (e.value) {
                var data = { ...employeeTemplate, [e.target.name]: e.value }
                console.log(data);
                if (initialValue?.userType == 'customer') {
                    console.log(rfidMachines);
                    setSelectedRfidForEdit(rfidMachines)
                    const filDataId = rfidMachines?.map((x) => { return x?.id })
                    console.log(filDataId);
                    var data = { ...employeeTemplate, machineid: filDataId, [e.target.name]: e.value }
                }
            } else {
                var data = { ...employeeTemplate, machineid: singleMachine ? [singleMachine.id] : [props.selectedMachine?.id], [e.target.name]: e.value }
            }

        }

        setEmployeeTemplate(data)
    }

    const changeRfidMch = (e) => {
        if (e.target.name == 'machineid') {
            console.log(e.value);
            setSingleMachine(e.value)
            setMultiMachines(null)

            setSelectedRfidForEdit(e.value)
            const filDataId = e.value?.map((x) => { return x.id })
            console.log(filDataId);
            setEmployeeTemplate({ ...employeeTemplate, [e.target.name]: filDataId })
        }
    }


    const onEmployeeSubmit = async (e) => {
        e.preventDefault();

        if (editedEmployee) {
            const data = { ...employeeTemplate };
            console.log(data);
            const res = await apiServices.updateEmployee(data);
            res?.data?.success ? successToast(toast, 'Employee Updated Successfully') : failureToast(toast, `Failed to Update, ${res?.data?.msg}`);
        } else {
            const data = { ...employeeTemplate, editbydetails: { customer: initialValue.userid, name: initialValue.name, userType: initialValue.userType } }
            console.log(data);
            const res = await apiServices.addEmployee(data);
            res?.data?.success ? successToast(toast, 'Employee Added Successfully') : failureToast(toast, res?.data?.msg || 'Failed to Add');
        }

        props.setemployeeDialog(false)
        setEditedEmployee(null)
        props.getEmployees(selectedMach)
        clearInputs()
    }

    const hideEmpProductDialog = () => {
        setDeleteEmpDialog(false);
    }

    const confirmDeleteEmp = (rowData) => {
        setSelectedEmp(rowData)
        setDeleteEmpDialog(true);
    }

    const addNfc = (rowData) => {
        console.log(rowData);
        setSelectedEmp(rowData)
        setAddNfcDialog(true)
    }

    const onNfcSubmit = (e) => {
        e.preventDefault();
        props.onNfcSubmit(selectedEmp)
        setAddNfcDialog(false)
    }

    const deleteEmployee = () => {
        props.deleteEmployee(selectedEmp)
        setDeleteEmpDialog(false);
    }

    const clearInputs = () => {
        setSelectedCard(null)
        setSingleMachine(null)
        setMultiMachines(null)
        setSelectedLimit(null)
        setSelectedAssigned(null)
        setSelectedPeriod(null)
        setEmployeeTemplate({})
        setOutput(null)
        setSelectedRfidForEdit(null)
        setOnlineValidate(false)
    }

    const editEmployee = (rowData) => {
        console.log(rowData);
        setEditedEmployee(rowData)
        setEmployeeTemplate(rowData)
        props.setemployeeDialog(true)

        if (rowData.cardtype == 'nfc') {
            setSelectedCard({ name: 'NFC', code: 'nfc' })
            setSingleMachine(null)

            const arr = [];
            for (let i = 0; i < rowData.machineid?.length; i++) {
                console.log(rowData.machineid[i]);
                const mach = allMachines.filter((x) => {
                    return x.id == rowData.machineid[i]
                })
                arr.push(mach[0])
            }

            setMultiMachines(arr)

        } else if (rowData.cardtype == 'rfid') {

            console.log(props.selectedMachine.id);
            console.log(rowData.machineid);

            setSelectedCard({ name: 'RFID', code: 'rfid' })
            setMultiMachines(null)

            // rowData.machineid.length > 1

            console.log(allMachines);

            // const mach = allMachines.filter((x)=>{
            //     if(x.id = props.selectedMachine.id){
            //         return x;
            //     }
            // })

            const mach = allMachines.filter((x) => {
                return x.id == rowData.machineid;
            })

            setSingleMachine(mach[0])
            setOnlineValidate(rowData?.onlineValidate)
        }

        if (rowData.duration.period == 'always') {
            setSelectedPeriod({ name: 'Always', code: 'always' })
        } else if (rowData.duration.period == 'date') {
            setSelectedPeriod({ name: 'Timely', code: 'date' })
            const startDate = rowData?.duration?.startDate?.split('T')[0];
            const endDate = rowData?.duration?.endDate?.split('T')[0];
            setSelectedDates({ startDate: startDate, endDate: endDate })
        } else if (rowData.duration.period == 'recharge') {
            setSelectedPeriod({ name: 'Recharge', code: 'recharge' })
        }

        if (rowData.duration.cup_limit == 'daily') {
            setSelectedLimit({ name: 'Daily', code: 'daily' })
        } else if (rowData.duration.cup_limit == 'weekly') {
            setSelectedLimit({ name: 'Weekly', code: 'weekly' })
        } else if (rowData.duration.cup_limit == 'monthly') {
            setSelectedLimit({ name: 'Monthly', code: 'monthly' })
        } else if (rowData.duration.cup_limit == 'none') {
            setSelectedLimit({ name: 'None', code: 'none' })
        }

        if (rowData?.duration.assignby == 'cups') {
            setSelectedAssigned({ name: 'Cups', code: 'cups' })
        } else if (rowData?.duration.assignby == 'cost') {
            setSelectedAssigned({ name: 'Cost', code: 'cost' })
        }

        setOutput(rowData?.cup_count?.fullcup)

        if (rowData?.onlineValidate) {

            console.log(rfidMachines);
            console.log(props.selectedMachine);

            // rowData?.machineid
            const machineData = rfidMachines?.filter((x) => {
                if (rowData?.machineid.includes(x?.id)) {
                    return x;
                }
            })

            setSelectedRfidForEdit(machineData);

            const AllData = rfidMachines.filter((x) => {
                return x.customer == props.selectedMachine?.customer;
            })

            console.log(AllData);
            setCustomerRfidMachines(AllData)

            // props.selectedMachine

        }
    }

    const changeCups = (e) => {
        const data = { ...selectedEmp, cup_count: { halfcup: e.target.value, fullcup: e.target.value } }
        setSelectedEmp(data)
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }


    // const exportExcel = () => {
    //     import('xlsx').then((xlsx) => {
    //         const worksheet = xlsx.utils.json_to_sheet(machineEmp);
    //         const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    //         const excelBuffer = xlsx.write(workbook, {
    //             bookType: 'xlsx',
    //             type: 'array'
    //         });

    //         saveAsExcelFile(excelBuffer, 'products');
    //     });
    // };

    const exportExcel = () => {

        if (dataToExport?.length > 0) {

            import('sheetjs-style').then((xlsx) => {
                const worksheet = xlsx.utils.json_to_sheet(dataToExport);
                const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
                const ws_name = workbook.SheetNames[0];
                const ws = workbook.Sheets[ws_name];

                const headerLength = Object.keys(dataToExport[0]).length;
                const wscols = Array(headerLength).fill({ wch: 30 })

                const headerStyle = {
                    font: {
                        name: 'arial',
                        sz: 12,
                        bold: true,
                        color: "#F2F2F2"
                    },
                    fill: {
                        patternType: 'solid',
                        fgColor: { rgb: "FFFF00" },
                        bgColor: { rgb: "FF1000" }
                    },
                    alignment: {
                        vertical: 'center',
                        horizontal: 'center',
                        wrapText: true
                    }
                }

                ws['!cols'] = wscols;

                ws['A1'].s = headerStyle;
                ws['B1'].s = headerStyle;
                ws['C1'].s = headerStyle;
                ws['D1'].s = headerStyle;

                // const keyyyy = Object.keys(ws);

                // console.log(keyyyy);

                // const funct = (x) =>{
                //     const stylebody = {
                //         alignment: {
                //             vertical : 'center',
                //             horizontal : 'center',
                //             wrapText : true
                //         }
                //     }


                //     ws[x].s = stylebody;
                // }


                const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
                const fileName = `Employee Details (${selectedMach?.id})`
                saveAsExcelFile(excelBuffer, fileName);
            });
        }
    };


    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + EXCEL_EXTENSION);
            }
        });
    };

    const hideDialog = () => {
        props.setemployeeDialog(false)
        setEditedEmployee(null)
        clearInputs()
    }

    const rechargeCard = (data) => {
        console.log(data);
        console.log(props.selectedMachine);
        setSelectedEmp(data)
        setRechargeDialog(true)

        const uniq = (new Date()).getTime();
        console.log(uniq);
        setOrdersId(uniq)
    }

    const changeRechargeCup = (e) => {
        // console.log(e.target.value);
        // console.log(props.selectedMachine?.subscription?.package?.fullcup);
        if (e.target.name == 'cupage') {
            setRechargeCups(e.target.value)
            const val = Number(props.selectedMachine?.subscription?.package?.fullcup) * Number(e.target.value);
            setRechargeAmt(val)
        }

        if (e.target.name == 'bulkCupage') {
            setRechargeCups(e.target.value)
            const val = Number(props.selectedMachine?.subscription?.package?.fullcup) * selectedCards?.length * Number(e.target.value);
            setRechargeAmt(val)
        }

        if (e.target.name == 'cards') {
            console.log(e.value);
            setSelectedCards(e.value)
            setRechargeCups(0)
            setRechargeAmt(0)
        }
    }


    const onRechageSubmit = async (e) => {
        e.preventDefault();
        handlePayment()
    }

    const onBulkRechargeSubmit = (e) => {
        e.preventDefault();
        handlePayment('bulk')
        // updateBulkTransaction()
    }

    const handlePayment = async (data) => {

        console.log(rechargeCups);

        const options = {
            key: RPAY_KEY,
            amount: rechargeAmt * 100,
            // amount: 1 * 100,
            currency: "INR",
            name: "Cherise",
            description: "Cherise RFID Recharge",
            // order_id: data?.razorpay_order_id,
            handler: function (response) {

                console.log(response);
                // alert(response.razorpay_payment_id);
                // alert(response.razorpay_order_id);
                // alert(response.razorpay_signature);
                if (response?.status_code == 200) {

                    updateTransHistory(response)

                    if (data == 'bulk') {
                        updateBulkTransaction()
                    } else {
                        updateTransaction()
                    }
                }
            },
            prefill: {
                name: initialValue.name,
                email: initialValue.email,
                contact: initialValue.cno,
            },
            // notes: {
            //     address: "Razorpay Corporate Office",
            // },
            // 'notes.shopping_order_id': data?.shoppingOrderId,
            theme: {
                color: "#3399cc",
            },
        };

        const rzp1 = new Razorpay(options);
        console.log(rzp1);
        addTransHistory(rzp1)

        rzp1.on("payment.failed", function (response) {
            transactionFailed()
        });

        rzp1.on("payment.error", function (response) {
            transactionFailed()
        });

        rzp1.on("payment.external_wallet", function (response) {
            transactionFailed()
        });

        rzp1.on("payment.success", function (response) {
            console.log(response);
            updateTransaction(response)
            // setDefaultOrderValues()
        });

        rzp1.open();
    };

    const addTransHistory = async (data) => {

        const payload = {
            "machineId": selectedMach?.id,
            "subid": selectedMach?.subid,
            "orderId": ordersId,
            "cups": Number(rechargeCups),
            "amount": rechargeAmt
        }

        const response = await apiServices.addRfidTransactions(payload);
        console.log(response);
    }

    const updateTransHistory = async (data) => {

        const payload = {
            "orderId": ordersId,
            "txn_details": {
                "razorpay_payment_id": data?.razorpay_payment_id
            },
            "status": true
        }

        const response = await apiServices.updateRfidTransactions(payload);
        console.log(response);
    }

    const transactionFailed = () => {
        toast.current.show({ severity: 'error', summary: 'Failed', detail: `Payment Failed`, life: 3000 });
    }

    const updateTransaction = async () => {

        const payload = { ...selectedEmp, cupCounts: Number(selectedEmp?.cupCounts) + Number(rechargeCups), cup_count: { "fullcup": (Number(selectedEmp?.cup_count?.fullcup) + Number(rechargeCups)), "halfcup": (Number(selectedEmp?.cup_count?.halfcup) + Number(rechargeCups)) }, remainCount: { "fullcup": (Number(selectedEmp?.remainCount?.fullcup) + Number(rechargeCups)), "halfcup": (Number(selectedEmp?.remainCount?.halfcup) + Number(rechargeCups)) } }
        console.log(payload);

        const res = await apiServices.updateEmployee(payload);
        if (res?.data?.success) {
            successToast(toast, 'Employee Updated Successfully')
            hideRechargeDialog()
            // getData(props.mchEmployee)
            props.getEmployees(selectedMach)
        } else {
            failureToast(toast, 'Failed to Update')
        }
    }

    const updateBulkTransaction = async () => {

        const rfidArr = selectedCards.map((x) => {

            if (x?.reset_count) {
                return {
                    ...x,
                    cupCounts: x.cupCounts + Number(rechargeCups),
                    cup_count: { fullcup: x.cup_count?.fullcup + Number(rechargeCups), halfcup: x.cup_count?.halfcup + Number(rechargeCups) },
                    remainCount: { fullcup: x.remainCount?.fullcup + Number(rechargeCups), halfcup: x.remainCount?.halfcup + Number(rechargeCups) },
                    reset_count: { fullcup: x.reset_count?.fullcup + Number(rechargeCups), halfcup: x.reset_count?.halfcup + Number(rechargeCups) }
                }
            } else {
                return {
                    ...x,
                    cupCounts: x.cupCounts + Number(rechargeCups),
                    cup_count: { fullcup: x.cup_count?.fullcup + Number(rechargeCups), halfcup: x.cup_count?.halfcup + Number(rechargeCups) },
                    remainCount: { fullcup: x.remainCount?.fullcup + Number(rechargeCups), halfcup: x.remainCount?.halfcup + Number(rechargeCups) },
                }
            }
        });

        const response = await apiServices.updateBulkEmployee(rfidArr)
        console.log(response);

        if (response?.data?.success) {
            successToast(toast, 'Employee Updated Successfully');
            hideBulk();
            // getData(props.mchEmployee);
            props.getEmployees(selectedMach)
        } else {
            failureToast(toast, 'Failed to Update')
        }
    }

    const hideRechargeDialog = () => {
        setRechargeDialog(false);
        setRechargeAmt(0)
        setRechargeCups(0)
        setOrdersId(null)
    }

    const hideBulk = () => {
        setBulkRechargeDialog(false)
        setSelectedCards([])
        setRechargeCups(0)
        setRechargeAmt(0)
        setOrdersId(null)
    }

    const openBulk = () => {
        setBulkRechargeDialog(true)
        const uniq = (new Date()).getTime();
        setOrdersId(uniq)
    }

    const openTransList = () => {
        setTransDialog(true)
        getTransList()
    }

    const getTransList = async () => {
        const response = await apiServices.getRfidTransactions({ "machineId": selectedMach.id });
        console.log(response?.data?.data);
        setAllTransations(response?.data?.data)
    }

    const idBodyTemplate = (rowData, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {props.rowIndex + 1}
            </>
        );
    }

    const commonBodyTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {data[props.field]}
            </>
        );
    };

    const commonStatusBodyTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {data[props.field] ? <i className="pi pi-check"> {data?.txn_details?.razorpay_payment_id ? data?.txn_details?.razorpay_payment_id : ''}  </i> : <i className="pi pi-times"></i>}
            </>
        );
    };

    const mchBodyTemplate = (rowData, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {rowData[props.field]?.[0]}
            </>
        )
    }

    // const cupBodyTemplate = (rowData,props) => {
    //     return (
    //         <>  
    //             <span className="p-column-title">{props.header}</span>
    //             {rowData[props.field]?.fullcup ? rowData[props.field]?.fullcup : '-'}
    //         </>
    //     )
    // }

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Action</span>
                <div className="actions">
                    {rolePermissions?.updateEmployee && <Button icon="pi pi-pencil" title='Edit' className="p-button-rounded p-button-success  p-mr-2 p-mb-2" onClick={() => editEmployee(rowData)} />}
                    {rolePermissions?.deleteEmployee && <Button icon="pi pi-trash" title='Delete' className="p-button-rounded p-button-danger  p-mr-2 p-mb-2" onClick={() => confirmDeleteEmp(rowData)} />}
                    {(rowData.cardtype == 'nfc' && rolePermissions?.addNfc) && <Button icon="pi pi-plus" title='Add NFC Cups' className="p-button-rounded p-button-info p-mr-2 p-mb-2" onClick={() => addNfc(rowData)} />}
                    {
                        (rolePermissions?.rfidRecharge && rowData?.duration?.period == 'recharge') &&
                        <Button icon="pi pi-plus" title='Recharge Cups' className="p-button-rounded p-button-warning p-mr-2 p-mb-2" onClick={() => rechargeCard(rowData)} />
                    }
                </div>
            </>
        );
    }

    const header = (
        <div className="table-header">
            <h6 className="p-mt-3">Employee</h6>
            <span className="p-input-icon-left">
                <div className="p-inputgroup">
                    <Button className='custom-group-btn' icon="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
                    <Button type="button" icon="pi pi-file-excel" severity="success" onClick={exportExcel} data-pr-tooltip="XLS" rounded="true" />
                    {
                        rolePermissions?.downloadRfid &&
                        <Button  icon="pi pi-download" className='custom-group-btn p-button-rounded p-mt-2 p-mx-4'  onClick={exportCSV}/>
                    }

                    {
                        // initialValue.userid == 'usr-em890k81kypncp79' &&
                        (rechargedEmp?.length > 0 && rolePermissions?.bulkRfidRecharge) &&
                        <Button label="Bulk Recharge" icon="pi pi-money-bill" className="p-custom-btn p-m-1" onClick={openBulk} />
                    }
                    {
                        rolePermissions?.rfidRechargeList &&
                        <Button icon="pi pi-list" title='List of Transactions' className="p-button-rounded p-button-primary p-m-2" onClick={() => { openTransList() }} />
                    }

                </div>
            </span>
        </div>
    );

    const deleteEmpDialogFooter = (
        <>
            <Button label="Yes, Delete it" icon="pi pi-check" className=" p-button-danger p-mr-2 p-mb-2" onClick={deleteEmployee} />
            <Button label="No" icon="pi pi-times" className=" p-button-success p-mr-2 p-mb-2" onClick={hideEmpProductDialog} />
        </>
    );

    const deleteNfcDialogFooter = (
        <>
            <Button label="Close" icon="pi pi-times" className="p-button-danger p-mr-2 p-mb-2" onClick={() => setAddNfcDialog(false)} />
            <Button label="Submit" type='submit' form='nfc-form' icon="pi pi-check" className=" p-button-success p-mr-2 p-mb-2" />
        </>
    );

    const empDialogFooter = (
        <>
            <Button label="Close" icon="pi pi-times" className="p-button-danger p-mr-2 p-mb-2" onClick={hideDialog} />
            <Button label="Submit" type='submit' form='emp-form' icon="pi pi-check" className="p-button-success p-mr-2 p-mb-2" />
        </>
    );

    const rechargeCardDialogFooter = (
        <>
            <Button label="Close" icon="pi pi-times" className="p-button-danger p-mr-2 p-mb-2" onClick={hideRechargeDialog} />
            {
                (rechargeAmt > 0) && <Button label="Proceed to Pay" type='submit' form='recharge-form' icon="pi pi-check" className=" p-button-success p-mr-2 p-mb-2" />
            }
        </>
    );

    const bulkRechargeCardDialogFooter = (
        <>
            <Button label="Close" icon="pi pi-times" className="p-button-danger p-mr-2 p-mb-2" onClick={hideBulk} />
            {
                rechargeAmt > 0 && <Button label="Proceed to Pay" type='submit' form='bulk-recharge-form' icon="pi pi-check" className=" p-button-success p-mr-2 p-mb-2" />
            }
        </>
    );

    const transactionsFooter = (
        <>
            <Button label="Close" icon="pi pi-times" className="p-button-danger p-mr-2 p-mb-2" onClick={() => { setTransDialog(false) }} />
        </>
    );



    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={props.toast} position="center" />
                    <Toast ref={toast} position="center" />
                    <DataTable ref={dt} value={machineEmp} dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Employees"
                        globalFilter={globalFilter} emptyMessage="No data found." header={header} exportFilename={`Employee Details (${selectedMach?.id})`} loading={dataLoading}>
                        <Column field="id" header="Sr.No." sortable body={idBodyTemplate} exportable={false}></Column>
                        <Column field="name" header="Employee Name" sortable body={commonBodyTemplate}></Column>
                        {/* <Column field="machineid" header="CTVM Id" sortable body={mchBodyTemplate}></Column> */}
                        <Column field="cardtype" header="Card Type" sortable body={CommonBodyForFormattedStrTable}></Column>
                        <Column field="rfid" header="Card Id" sortable body={commonBodyTemplate}></Column>
                        <Column field="cupCounts" header="Cup Count" body={commonBodyTemplate}></Column>
                        <Column body={actionBodyTemplate} header="Action" exportable={false}></Column>
                    </DataTable>
                    {/* :<center><h5 className='p-error'>No Data Found</h5></center> */}
                    {/* } */}

                    <Dialog visible={deleteEmpDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteEmpDialogFooter} onHide={hideEmpProductDialog}>
                        <div className="confirmation-content">
                            <center>
                                <i className="pi pi-exclamation-triangle p-error" style={{ fontSize: '4rem' }} /><br /><br />
                                <b><span>Are you sure you want to delete Employee ({selectedEmp ? selectedEmp?.name : '-'}) ?</span></b> <br /><br />
                            </center>
                        </div>
                    </Dialog>

                    <Dialog visible={addNfcDialog} style={{ width: '650px' }} header="Add NFC Cups" modal className="p-fluid" footer={deleteNfcDialogFooter} onHide={() => { setAddNfcDialog(false) }}>
                        <form onSubmit={onNfcSubmit} id="nfc-form" className="p-grid">
                            <div className='p-col-6'>
                                <div className="p-field">
                                    <label htmlFor="card-type-id">Card Type</label>
                                    <InputText id="card-type-id" placeholder='Card Type' autoComplete="off" value={selectedEmp?.cardtype} disabled />
                                </div>
                            </div>
                            <div className='p-col-6'>
                                <div className="p-field">
                                    <label htmlFor="nfc-tag-no">NFC Tag Number</label>
                                    <InputText id="nfc-tag-no" placeholder='NFC Tag Number' value={selectedEmp?.rfid} disabled />
                                </div>
                            </div>
                            <div className='p-col-6'>
                                <div className="p-field">
                                    <label htmlFor="cup-rem">Cups Remaining</label>
                                    <InputText id="cup-rem" placeholder='Cups Remaining' value={selectedEmp?.cup_count?.halfcup} disabled />
                                </div>
                            </div>
                            <div className='p-col-6'>
                                <div className="p-field">
                                    <label htmlFor="cups">Add Cups</label>
                                    <InputText id="cups" type='number' min={1} name='name' placeholder='Add Cups' onChange={changeCups} defaultValue={selectedEmp?.cup_count?.halfcup} autoFocus />
                                </div>
                            </div>
                        </form>
                    </Dialog>

                    {/* Hello */}

                    <Dialog visible={props.employeeDialog} style={{ width: '650px' }} header={`${editedEmployee ? 'Edit' : 'Add'} Employee Details`} modal className="p-fluid" footer={empDialogFooter} onHide={hideDialog} maximizable={true} blockScroll={true}>
                        <form onSubmit={onEmployeeSubmit} id="emp-form" className="p-fluid">

                            <div className="p-field">
                                <label htmlFor="name">Name</label>
                                <InputText id="name" name='name' placeholder='Employee Name' autoComplete="off" onChange={changeInputs} defaultValue={editedEmployee?.name} autoFocus required />
                            </div>
                            <div className="p-field">
                                <label htmlFor="card-type-id">Card Type</label>
                                <Dropdown id="card-type-id" name='cardtype' options={cardTypeDropdownItems} onChange={changeInputs} value={selectedCard} optionLabel="name" placeholder="Select Card Type" required disabled={editedEmployee}></Dropdown>
                            </div>

                            {
                                selectedCard?.code == 'rfid' &&
                                <>
                                    <div className="p-field">
                                        <label htmlFor="online-v">Online Validate </label><br />
                                        <InputSwitch id="online-v" name='onlineValidate' checked={onlineValidate} onChange={changeInputs} />
                                    </div>

                                    {
                                        onlineValidate ?
                                            <div className="p-field">
                                                <label htmlFor="ctvm-id">CTVM Id (RFID)  </label>
                                                <MultiSelect id="ctvm-id" name='machineid' value={selectedRfidForEdit} options={editedEmployee ? (initialValue?.userType == 'customer' ? customerRfidMachines : rfidMachines) : rfidMachines} onChange={changeRfidMch} optionLabel="id" display="chip" placeholder="Select CVES" filter required />
                                            </div>
                                            :
                                            <div className="p-field">
                                                <label htmlFor="ctvm-id">CTVM Id (RFID)</label>
                                                <Dropdown id="ctvm-id" name='machineid' value={editedEmployee ? (singleMachine || props.selectedMachine) : singleMachine} onChange={changeInputs} options={rfidMachines} optionLabel="id" placeholder="Select CVES" filter required disabled={editedEmployee}></Dropdown>
                                            </div>
                                    }

                                    {/* <div className="p-field"> */}
                                    {/* <label htmlFor="ctvm-id">CTVM Id (RFID)</label> */}
                                    {/* <MultiSelect value={singleMachine} options={rfidMachines} name='machineid' onChange={changeInputs} optionLabel="id" placeholder="Select a CVES" display="chip" filter showSelectAll={false} required disabled={editedEmployee} /> */}
                                    {/* </div> */}

                                    <div className="p-field">
                                        <label htmlFor="rfid-tag">RFID Tag Number</label>
                                        <InputText id="rfid-tag" name='rfid' onChange={changeInputs} autoComplete="off" maxLength={12} defaultValue={editedEmployee?.rfid} placeholder='RFID Tag Number' required disabled={editedEmployee} />
                                    </div>
                                </>
                            }

                            {
                                selectedCard?.code == 'nfc' &&
                                <>
                                    <div className="p-field">
                                        <label htmlFor="ctvm-id">CTVM Id (NFC)</label>
                                        <MultiSelect value={multiMachines} options={nfcMachines} name='machineidNfc' onChange={changeInputs} optionLabel="id" placeholder="Select a CVES" display="chip" filter showSelectAll={false} required disabled={editedEmployee} />
                                    </div>

                                    <div className="p-field">
                                        <label htmlFor="nfc-tag">NFC Tag Number</label>
                                        <InputText id="nfc-tag" name='rfid' onChange={changeInputs} maxLength={12} placeholder='NFC Tag Number' defaultValue={editedEmployee?.rfid} required disabled={editedEmployee} />
                                    </div>
                                </>
                            }

                            <div className="p-field">
                                <label htmlFor="duration-id">Duration Period</label>
                                <Dropdown id="duration-id" name='period' value={selectedPeriod} onChange={changeInputs} options={periodDropdownItems} optionLabel="name" placeholder="Select Duration Period" required></Dropdown>
                            </div>

                            {
                                selectedPeriod?.code != 'recharge' &&
                                <div className="p-field">
                                    <label htmlFor="cup-limit">Cup Duration</label>
                                    <Dropdown id="cup-limit" name='cup_limit' value={selectedLimit} onChange={changeInputs} options={limitDropdownOptions} optionLabel="name" placeholder="Select Cup Duration" required></Dropdown>
                                </div>
                            }

                            {
                                (selectedPeriod?.code == 'date' || selectedPeriod?.code == 'always') &&
                                <>
                                    <div className="p-field">
                                        <label htmlFor="start-date">Start Date </label>
                                        {/* <InputText id="start-date" type="date" name='startDate' min={editedEmployee ? null : toDate} onChange={changeInputs} placeholder='Start Date' defaultValue={selectedDates?.startDate} required /> */}
                                        <InputText id="start-date" type="date" name='startDate' min={editedEmployee ? null : toDate} onChange={changeInputs} placeholder='Start Date' defaultValue={selectedDates?.startDate ? selectedDates?.startDate : toDate} required />
                                    </div>
                                    <div className="p-field">
                                        <label htmlFor="end-date">End Date</label>
                                        <InputText id="end-date" type="date" name='endDate' min={minDate} onChange={changeInputs} placeholder='End Date' defaultValue={selectedDates?.endDate ? selectedDates?.endDate : nextYrDate} required />
                                    </div>
                                    <div className="p-field">
                                        <label htmlFor="start-int">Start Interval</label>
                                        <InputText id="start-int" type="time" name='startInter' onChange={changeInputs} placeholder='Start Interval' defaultValue={editedEmployee?.duration?.startInter ? editedEmployee?.duration?.startInter : '00:00'} required />
                                    </div>
                                    <div className="p-field">
                                        <label htmlFor="end-int">End Interval</label>
                                        <InputText id="end-int" type="time" name='endInter' onChange={changeInputs} placeholder='End Interval' defaultValue={editedEmployee?.duration?.endInter ? editedEmployee?.duration?.endInter : '23:59'} required />
                                    </div>
                                </>
                            }

                            <div className="p-field">
                                <label htmlFor="cup-limit">Assigned By</label>
                                <Dropdown id="cup-limit" name='assignby' value={selectedAssigned} onChange={changeInputs} options={assignedByoptions} optionLabel="name" placeholder="Select Assigned By" required></Dropdown>
                            </div>

                            {
                                // (selectedAssigned?.code == 'cups' && editedEmployee?.duration?.period != 'recharge') &&
                                (selectedAssigned?.code == 'cups') &&
                                <>
                                    <div className="p-field">
                                        <label htmlFor="cup-count">Cup Count</label>
                                        {/* <InputText id="cup-count" name='cup_count' type="number" min={0} max={255} value={output} placeholder='Cup Count' onChange={changeInputs} defaultValue={editedEmployee?.cup_count?.fullcup} required/> */}
                                        <InputText id="cup-count" name='cup_count' type="number" min={0} max={255} defaultValue={output} placeholder='Cup Count' onChange={changeInputs} required />
                                    </div>
                                </>
                            }
                            {
                                selectedAssigned?.code == 'cost' &&
                                <>
                                    <div className="p-field">
                                        <label htmlFor="costPerCup">Cost Per Cup</label>
                                        <InputText id="costPerCup" name='costpercup' type="number" min={0} onChange={changeInputs} placeholder='Cost Per Cup' defaultValue={editedEmployee?.duration?.costpercup} required />
                                    </div>
                                    <div className="p-field">
                                        <label htmlFor="total-cup">Total Cost</label>
                                        <InputText id="total-cup" name='totalcost' type="number" min={0} onChange={changeInputs} placeholder='Total Cost' defaultValue={editedEmployee?.duration?.totalcost} required />
                                    </div>
                                </>
                            }
                        </form>
                    </Dialog>

                    <Dialog visible={rechargeDialog} style={{ width: '650px' }} header="RFID Recharge" modal className="p-fluid" footer={rechargeCardDialogFooter} onHide={hideRechargeDialog}>
                        <form onSubmit={onRechageSubmit} id="recharge-form" className="p-grid">
                            <div className='p-col-6'>
                                <div className="p-field">
                                    <label htmlFor="cardcups">Cups You want to add?</label>
                                    <InputText id="cardcups" type='number' min={1} name='cupage' placeholder='Add Cups' onChange={changeRechargeCup} autoFocus required />
                                </div>
                            </div>
                            <div className='p-col-6'>
                                <div className="p-field">
                                    <label htmlFor="card-amount">Amount</label>
                                    <InputText id="card-amount" placeholder='Amount' value={rechargeAmt} autoComplete="off" disabled />
                                </div>
                            </div>
                        </form>
                    </Dialog>

                    <Dialog visible={bulkRechargeDialog} style={{ width: '650px' }} header="Bulk RFID's Recharge" modal className="p-fluid" footer={bulkRechargeCardDialogFooter} onHide={hideBulk}>
                        {
                            rechargedEmp?.length > 0 ?
                                <form onSubmit={onBulkRechargeSubmit} id="bulk-recharge-form" className="p-grid">
                                    <div className="p-col-12">
                                        <div className="p-field">
                                            <label htmlFor="select-card">Select Cards</label><br />
                                            <MultiSelect className="multiselect-custom" id="select-card" name="cards" optionLabel="rfid" placeholder="Select RFID's" options={rechargedEmp} onChange={changeRechargeCup} value={selectedCards} filter showClear={true} showSelectAll={false} display="chip" autoFocus />
                                        </div>
                                    </div>
                                    <div className='p-col-12'>
                                        <div className="p-field">
                                            <label htmlFor="cardcups">Cups You want to add?</label>
                                            <InputText id="cardcups" type='number' min={0} name='bulkCupage' placeholder='Add Cups' onChange={changeRechargeCup} value={rechargeCups} required />
                                        </div>
                                    </div>
                                    <div className='p-col-12'>
                                        <div className="p-field">
                                            <label htmlFor="card-amount">Amount</label>
                                            <InputText id="card-amount" placeholder='Amount' value={rechargeAmt} autoComplete="off" disabled />
                                        </div>
                                    </div>
                                </form>
                                :
                                <center><h5 className='p-error'>Not Available</h5></center>
                        }
                    </Dialog>

                    <Dialog visible={transDialog} style={{ width: '850px' }} header={`List of Rfid Recharge Transactions (${selectedMach?.id})`} modal footer={transactionsFooter} onHide={() => { setTransDialog(false) }}>
                        <div className="p-grid crud-demo">
                            <div className="p-col-12">
                                <div className="card">
                                    <DataTable value={allTransations} dataKey="id" className="datatable-responsive"
                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Employees"
                                        emptyMessage="No data found.">
                                        <Column field="id" header="Sr.No." body={idBodyTemplate} exportable={false}></Column>
                                        <Column field="cups" header="Cups" body={commonBodyTemplate}></Column>
                                        <Column field="amount" header="Amount" body={commonBodyTemplate}></Column>
                                        <Column field="Date" header="Date" body={commonBodyTemplate}></Column>
                                        <Column field="status" header="Transation Status/Transaction Id" body={commonStatusBodyTemplate}></Column>
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </Dialog>


                </div>
            </div>
        </div>
    );
}
