import React, { useEffect, useState } from "react";
import { Chart } from "primereact/chart";

export const AccWiseInventoryChart = (props) =>{

    const [data1,setData1] = useState(null)
    const [data2,setData2] = useState(null)
    const [label,setLabel] = useState(null)

    useEffect(()=>{
        getData(props.inventoryPerData)
    },[props.inventoryPerData])

    const getData = (data) =>{
        if(data){
            console.log(data);
            const loaded = data.map(x => x.loaded_packets)
            setData1(loaded)
            const used = data.map(x => x.used_packets)
            setData2(used)
            const label = data.map(x => x.siteName2)
            setLabel(label)
        }
    }
    

    const stackedData = {
        labels: label,
        datasets: [{
            type: 'bar',
            label: 'Loaded Packets',
            backgroundColor: '#06685d4a',
            data: data1,
            borderRadius: 5
        }, {
            type: 'bar',
            label: 'Used Packets',
            backgroundColor: '#00544D',
            data: data2,
            borderRadius: 5
        }]
    };
    
    const stackedOptions = {
        maintainAspectRatio: false,
        aspectRatio: .5,
        plugins: {
            tooltips: {
                mode: 'index',
                intersect: false
            },
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        },
        scales: {
            x: {
                stacked: true,
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            },
            y: {
                stacked: true,
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            }
        }
    };

    return (
        
        <div className="card">            
            <Chart type="bar" data={stackedData} options={stackedOptions} />
        </div>

    )
}