import React, {useState,useEffect} from "react";
import { Chart } from "primereact/chart";

export const AccWiseTargetChart = (props) =>{

    const [dataLabels,setDataLabels] = useState(null)
    const [dataValues,setDataValues] = useState(null)

    useEffect(()=>{
        console.log(props.accWiseTargetData)
        getData(props.accWiseTargetData)
    },[props.accWiseTargetData])

    const getData = (data) =>{
        if(data){
            console.log(data);
            const values = data.map(x => x.ratio)
            setDataValues(values)
            const labels = data.map(x => x.siteName)
            setDataLabels(labels)
        }
    }

    const barData = {
        labels: dataLabels,
        datasets: [
            {
                label: 'Accounts',
                backgroundColor: '#00544d',
                borderColor: 'rgb(255, 99, 132)',
                data: dataValues,
                borderRadius: 5
            }
        ]
    };

    const barOptions = {
        plugins: {
            legend: {
                // labels: {
                //     color: '#A0A7B5'
                // }
                display:false
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color:  'rgba(160, 167, 181, .3)',
                }
            },
            y: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color:  'rgba(160, 167, 181, .3)',
                }
            }
        }
    };

    
    return(

        <div className="card">            
            {/* <h6 className="centerText"></h6> */}
            <Chart type="bar" data={barData} options={barOptions} />
        </div>
    
    )
}