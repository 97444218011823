import React, { useEffect, useRef, useState, useContext } from 'react'
import ApiServices from '../../service/api/ApiServices'
import { CommonBodyForTable, commonDialogFooter, commonFormDialogFooter, srBodyTemplate } from '../Common/CommonComponents';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { failureToast, successToast } from '../Common/CommonFunctions';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { permissionContext } from "../../AppWrapper";


const saved = localStorage.getItem("user");
const initialValue = JSON.parse(saved);

const apiServices = new ApiServices();

// =======================================================================================
// =======================================================================================

export const CvesActions = () => {

    const rolePermissions = useContext(permissionContext);

    const [loading, setLoading] = useState(true)
    const [responseData, setResponseData] = useState(null)
    const [switchDialog, setSwitchDialog] = useState(false)
    const [deleteCVESDialog, setDeleteCVESDialog] = useState(false)
    const [deleteSwitchDialog, setDeleteSwitchDialog] = useState(false);
    const [deleteSwitchId, setDeleteSwitchId] = useState(null);
    const [selectedMachine, setSelectedMachine] = useState(null)
    const [switchMachine, setSwitchMachine] = useState(null);
    const [switchCheckStatus, setSwitchCheckStatus] = useState(true);

    const [consumptionCheck, setConsumptionCheck] = useState(false)
    const [cardsCheck, setCardsCheck] = useState(false)
    const [counterCheck, setCounterCheck] = useState(false)
    const [pendingCheck, setPendingCheck] = useState(false)

    const [allFgMachines, setAllFgMachines] = useState(null)
    const [fgMachines, setFgMachines] = useState(null)
    const [selectedMachineForM, setSelectedMachineForM] = useState(null)

    const toast = useRef(null);

    useEffect(() => {
        getData()
        getMachines()
    }, [])

    const getData = async () => {
        const response = await apiServices.getCVESReports({});
        console.log(response?.data?.data);
        setResponseData(response?.data?.data)
        setLoading(false)
    }

    const getMachines = async () => {
        const payload = { brand: ['TAPRI', 'TKAFFE', 'MERI_TAPRI'] }
        const response = await apiServices.findMachines(payload);
        console.log(response?.data?.data);
        const finishedGoodMachines = response?.data?.data?.filter((m) => { return m.datastore.stage === "finishedgoods" && m.siteName === "" })
        console.log(finishedGoodMachines);

        setAllFgMachines(finishedGoodMachines)
        setFgMachines(finishedGoodMachines)
    }

    const openSwitchDialog = (rowData) => {
        console.log(rowData);
        setSwitchDialog(true);
        setSelectedMachine({ ...rowData, id: rowData?.machineId })
        if (rowData?.action_type == 'SWITCH') {
            const filterData = allFgMachines.filter((x) => { return x.id == rowData?.switch_machineId });
            setFgMachines(filterData)
        } else {
            setFgMachines(allFgMachines)
        }
    }

    const confirmDeleteSwitch = (rowData) => {
        setDeleteSwitchId({ ...rowData, _id: rowData?._id });
        console.log("switch delete Id ==>", { ...rowData, _id: rowData?._id })
        setDeleteSwitchDialog(true);
    }

    const confirmDeleteCVES = (rowData) => {
        setDeleteCVESDialog(true);
        setSelectedMachine({ ...rowData, id: rowData?.machineId })
        console.log({ ...rowData, id: rowData?.machineId });
    }

    const deleteCVES = async () => {
        const response = await apiServices.deleteSite(selectedMachine);
        if (response.data.type == 'success') {
            successToast(toast, 'CVES Machine Deleted Successfully')
            setLoading(false)
            hideCVESDialog()
            getData()
        } else {
            failureToast(toast, 'Failed to Delete')
        }
    }


    const deleteSwitch = async () => {
        console.log("Switch Id ==>", deleteSwitchId);
        setLoading(true);
        const response = await apiServices.deleteCvesReport({ _id: deleteSwitchId._id });
        if (response.data.success) {
            successToast(toast, 'Switch Record Deleted Successfully');
            setDeleteSwitchDialog(false);
            setLoading(false)
            getData()
            setDeleteSwitchId(null)
        } else {
            failureToast(toast, 'Failed to Delete')
        }

    }

    const saveSwitchMachine = async (e) => {
        e.preventDefault();
        setSwitchDialog(false)
        setSwitchMachine(null)

        if (switchMachine?.connectionMode != "disConnected" || selectedMachine?.app_cat == "Tapri Kettle") {
            const data = { id: selectedMachine?.id, new_id: switchMachine?.id, customer: selectedMachine?.userid, owner: initialValue?.userid }
            console.log(data);
            const response = await apiServices.switchMachine(data);
            if (response?.data?.type !== 'error') {
                successToast(toast, 'CVES Machine Switched Successfully')
                getData()
            } else {
                failureToast(toast, response?.data?.msg || 'Failed to Switch')
            }
        }
    }

    const setSwitchDefault = () => {
        setConsumptionCheck(false);
        setCardsCheck(false);
        setCounterCheck(false);
        setPendingCheck(false);
    }

    const changeSwitchMachine = (e) => {
        console.log(e.value);
        setSwitchMachine(e.value)
        setSwitchDefault()
        if (e.value.connectionMode != "disConnected" || selectedMachineForM?.app_cat == "Tapri Kettle") {
            setSwitchCheckStatus(false)
        }
    }

    const switchingChecks = async (e) => {
        console.log(e.target.checked)
        if (e.target.checked) {
            const response = await apiServices.switchMachineCheck({ check: e.target.name, id: switchMachine?.id });
            if (response?.data?.success) {
                e.target.name == 'consumption' && setConsumptionCheck(e.target.checked)
                e.target.name == 'cards' && setCardsCheck(e.target.checked)
                e.target.name == 'counter' && setCounterCheck(e.target.checked)
                e.target.name == 'pending' && setPendingCheck(e.target.checked)
            }
        }
    }

    const actionBodyForTable = (rowData) => {

        return (
            <>
                <span className="p-column-title">Action</span>
                <div className="actions" style={{ display: "flex" }}>
                    {
                        ((rolePermissions?.deleteSwitchReport || rolePermissions?.deletePulloutReport) && rowData?.action_type === 'PULLOUT') && <Button icon="pi pi-trash" title='Delete' className="p-button-rounded p-button-danger p-mx-2" aria-label="Delete" onClick={() => confirmDeleteCVES(rowData)} />
                    }
                    {
                        (rolePermissions?.deleteSwitchReport && rowData?.action_type === 'SWITCH') && <Button icon="pi pi-sliders-h" title='Switch' className="p-button-rounded p-button-warning p-mx-2" aria-label="Delete" onClick={() => { openSwitchDialog(rowData) }} />
                    }
                    {
                        (rolePermissions?.deleteSwitchReport && rowData?.action_type === 'SWITCH') && <Button icon="pi pi-trash" title='Delete' className="p-button-rounded p-button-danger p-mx-2" aria-label="Delete" onClick={() => confirmDeleteSwitch(rowData)} />
                    }
                </div>
            </>
        )
    }

    const hideCVESDialog = () => { setDeleteCVESDialog(false) }
    const hideSwitchDeleteDialog = () => { setDeleteSwitchDialog(false) }

    const hideSwitchDialog = () => {
        setSwitchDialog(false);
        // setMachineDropdownItem(null);    
        setSwitchDefault()
        setSwitchMachine(null)
    }

    const deleteCVESDialogFooter = commonDialogFooter('Cancel', hideCVESDialog, 'Yes, Delete it', deleteCVES)
    const switchDialogFooter = commonFormDialogFooter('Close', hideSwitchDialog, 'Switch', 'switch-form', ((consumptionCheck && cardsCheck && counterCheck && pendingCheck) && !switchCheckStatus))
    const deleteSwitchDialogFooter = commonDialogFooter('Cancel', hideSwitchDeleteDialog, 'Yes, Delete it', deleteSwitch)

    return (
        <>
            <Toast ref={toast} position='center' />
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">
                        {/* <Toolbar className="p-mb-4 p-toolbar" right={rightToolbarTemplate}></Toolbar> */}
                        <DataTable value={responseData}
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} record's" emptyMessage="No data found." loading={loading}>
                            <Column field="srno" header="Sr.No." style={{ width: '8rem' }} sortable body={srBodyTemplate}></Column>
                            <Column field="machineId" header="CVES Id" sortable body={CommonBodyForTable}></Column>
                            <Column field="compnay_name" header="Account Name" sortable body={CommonBodyForTable}></Column>
                            <Column field="action_type" header="Category" sortable body={CommonBodyForTable}></Column>
                            <Column field="action_type" header="Action" sortable body={actionBodyForTable}></Column>
                        </DataTable>
                    </div>
                </div>
            </div>

            <Dialog visible={deleteCVESDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteCVESDialogFooter} onHide={hideCVESDialog}>
                <div className="confirmation-content">
                    <center>
                        <i className="pi pi-exclamation-triangle p-error" style={{ fontSize: '4rem' }} /><br /><br />
                        <b><span>Are you sure you want to delete CVES?</span></b> <br /><br />
                        <span>You wont be able to revert.</span>
                    </center>
                </div>
            </Dialog>

            <Dialog visible={deleteSwitchDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteSwitchDialogFooter} onHide={hideSwitchDeleteDialog}>
                <div className="confirmation-content">
                    <center>
                        <i className="pi pi-exclamation-triangle p-error" style={{ fontSize: '4rem' }} /><br /><br />
                        <b><span>Are you sure you want to delete switch CVES record?</span></b> <br /><br />
                        <span>You wont be able to revert.</span>
                    </center>
                </div>
            </Dialog>

            <Dialog visible={switchDialog} style={{ width: '550px' }} header={`Switch CVES`} modal footer={switchDialogFooter} onHide={hideSwitchDialog} className="p-fluid">
                <form onSubmit={saveSwitchMachine} id="switch-form">
                    <div className="p-field">
                        <label htmlFor="switch-by">Switch By</label>
                        <Dropdown id="switch-by" value={switchMachine} onChange={changeSwitchMachine} autoFocus options={fgMachines} optionLabel="id" placeholder="Select CVES Id" filter required></Dropdown>
                        <small className='p-error'>
                            {
                                selectedMachine?.switch_machineId ?
                                    <>
                                        CVES Id <b>{selectedMachine?.switch_machineId}</b> Should be in Finished good stage.
                                    </>
                                    : 'Switch Machine Id is Required'
                            }
                        </small>
                        <p className='p-mt-2 p-error'>{switchMachine?.connectionMode == "disConnected" && '*CVES is Offline'} </p>

                        {
                            (switchMachine != null && (switchMachine?.connectionMode != "disConnected" || selectedMachineForM?.app_cat == "Tapri Kettle")) &&

                            <>
                                <div className="field-checkbox p-m-2">
                                    <Checkbox inputId="consumption" name="consumption" value="consumption" checked={consumptionCheck} onChange={(e) => { switchingChecks(e) }} />
                                    <label htmlFor="consumption"> CVES Consumption Data</label>
                                </div>
                                <div className="field-checkbox p-m-2">
                                    <Checkbox inputId="cards" name="cards" value="cards" checked={cardsCheck} onChange={(e) => { switchingChecks(e) }} />
                                    <label htmlFor="cards"> Cards on CVES</label>
                                </div>
                                <div className="field-checkbox p-m-2">
                                    <Checkbox inputId="counter" name="counter" value="counter" checked={counterCheck} onChange={(e) => { switchingChecks(e) }} />
                                    <label htmlFor="counter"> CVES Counter</label>
                                </div>
                                <div className="field-checkbox p-m-2">
                                    <Checkbox inputId="pending" name="pending" value="pending" checked={pendingCheck} onChange={(e) => { switchingChecks(e) }} />
                                    <label htmlFor="pending"> CVES Updated</label>
                                </div>
                            </>
                        }
                    </div>
                </form>
            </Dialog>


        </>
    )
}
